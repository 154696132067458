import styled from "styled-components";
import LightBG from '../../img/Backgrounds/LightBG.png'

export const TeamContainer = styled.div`
    position: relative;
    width: 100%;
    height: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1a6930;
    background-image: url(${LightBG});
    padding-bottom: 35px;
    color: white;
    @media screen and (max-width: 1280px)
    {
        transition: 0.8s all ease;
        height: 1250px;
    }
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        height: 950px;
    }
`

export const TeamWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1280px)
    {
        transition: 0.8s all ease;
        flex-direction: row;
    }
`

export const ImgContainer = styled.div`
    height: 45%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;

    @media screen and (max-width: 1280px)
    {
        transition: 0.8s all ease;
        justify-content: center;
        flex-direction: column;
    }
    @media screen and (max-width: 780px)
    {
        transition: 0.8s all ease;
        width: 250px;
    }
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        width: 50%
       height: 100%;
       
    }
`

export const TeamMember = styled.div`

    display: flex;
    height: 100%;
    width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 10px;
    
    @media screen and (max-width: 1920px)
    {
        transition: 0.8s all ease;
        width: 325px;
        margin: 0;
    }
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        width: 175px;
        height: 300px;
        margin: 0;
    }
    
`

export const TeamImg = styled.img`
    
    border-radius: 50px;
    height: 200px;
    width: 200px;
    box-shadow: 3px 3px 6px black;
    &:hover{
        height: 220px;
    width: 220px;
        }

    @media screen and (max-width: 1920px)
    {
        transition: 0.8s all ease;
        height: 175px;
        width: 175px;
        &:hover{
        height: 177px;
        width: 177px;
        }
    }
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        height: 125px;
        width: 125px;
        &:hover{
        height: 127px;
        width: 127px;
        }
    }


`

export const TeamTitle = styled.div`
    position: relative;
    font-size: 85px;
    font-weight: normal;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    padding-bottom: 0;
    font-family: Modak;
    width: 80%;
    text-align: center;
    -webkit-text-stroke: 1px #184832;


    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        padding: 0;
        margin: 0;
      
        margin-top: 10%;
    }
`

export const TeamInfo = styled.div`
h1{
    font-size: 40px;
    font-family: Modak;
    font-weight: normal;
    text-transform: none;
}

p{
    font-family: Dosis;
    font-weight: bold;
    font-size: 25px;
    -webkit-text-stroke: 0px #184832;

}
-webkit-text-stroke: 1px #184832;

font-family: Modak;
font-size: 12px;
text-align: center;
width: 100%;
line-height: .75;
text-transform: uppercase;

@media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        h1{
            font-size: 25px;
            
        }
        p{
            font-size: 20px;
        }
    }
`

export const SocialIcon = styled.a`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    margin-left: 22px;
    margin-right: 22px;
    font-weight: bold;
    text-decoration: none;
    padding-top: 25px;
`

export const Socials = styled.div `
    display: flex;
    text-align: center;
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: lightgray;
    }
`