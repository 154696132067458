import styled from "styled-components";

export const CommunityContainer = styled.div`
height: 1200px;
width: 100%;
display: flex;
position: relative;
color: white;
background: #184832;
justify-content: center;
align-items: center;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    height: 1550px;
}
`
export const CommWrapper = styled.div`
height: 100%;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
border-bottom: 5px solid white;
z-index: 2;
flex-direction: row-reverse;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    flex-direction: column;
}
`

export const SubContainer = styled.div`
height: 80%;
width: 55%;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;

@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 95%;
    height: 45%;
}
`

export const DubContainer = styled.div`
height: 80%;
width: 45%;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;

@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 90%;
    height: 50%;
    margin-bottom: 3%;

}
`

export const CommTitle = styled.div`
width: 60%;
height: 25%;
font-size: 50px;
font-family: Modak;
text-align: center;
align-items: center;
justify-content: center;
display: flex;
margin: 1%;

background: #1a6930;
border-radius: 90px;
box-shadow: 5px 5px 10px black;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    font-size: 40px;
    margin-top: 3%;
    width: 90%;
    height: 15%;
    
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    font-size: 35px;
    margin-top: 3%;
    width: 80%
    
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 35px;
    margin-top: 3%;
    width: 100%
    
    
}
`

export const CommText = styled.div`
display: flex;
width: 60%;
height: 75%;
justify-content: center;
align-items: center;
padding: 4%;
margin-top: 2%;
color: white;
font-size: 22px;
font-family: Marker;
background: #1a6930;
border-radius: 25px;
box-shadow: 5px 5px 10px black;
text-align: center;

p2{
    font-size: 35px;
}
p3{
    font-size: 30px;
    font-weight: bold;
}
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    font-size: 20px;
    width: 80%;
    p2{
    font-size: 25px;
}
p3{
    font-size: 18px;
    font-weight: bold;
}
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    font-size: 18px;
    width: 80%;
    p2{
    font-size: 25px;
}
p3{
    font-size: 18px;
    font-weight: bold;
}
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 12px;
    width: 95%;
    margin-top: 10%;
    margin-bottom: 10%;
}
`



export const MemberSection = styled.div`
height: 25%;
width: 100%;
margin-top: 1%;
display: flex;
align-items: center;
justify-content: center;

`

export const Member = styled.div`
height: 100%;
width: 30%;
display: flex;
align-items: center;
justify-content: center;
margin: 0 2%;
flex-direction: row;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 33%;
 
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    flex-direction: column;
 
}
`
export const TextSection = styled.div`
display: flex;
flex-direction: column;
width: 55%;
height: 100%;
justify-content: center;
align-items: center;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 100%;
    height: 25%;
    justify-content: space-between;
    
}

`
export const MemberLink = styled.a`
height: 50%;
width: 35%;
display: flex;
justify-content: center;
align-items: center;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 50%;
    height: 45%;
  
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 55%;
    height: 50%;
  
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 95%;
    height: 50%;
  
}
`

export const MemberImg = styled.img`
height: 100%;
width: 100%;
border-radius: 50%;
box-shadow: 2px 2px 10px black;
`

export const MemberName = styled.div`
height: 15%;
width: 100%;
font-size: 35px;
margin-top: 5%;
font-family: Modak;
display: flex;
justify-content: left;
margin-left: 15%;
align-items: center;
-webkit-text-stroke: 2px #1a6930;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    font-size: 30px;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    font-size: 25px;
    justify-content: center;
    margin-left: 0;
    height: 50%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease
    font-size: 15px;
 
    
}
`

export const MemberJob = styled.div`
height: 15%;
width: 100%;
font-size: 20px;
font-family: Dosis;
font-weight: bold;
display: flex;
justify-content: left;
margin-left: 15%;
align-items: center;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    font-size: 16px;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    font-size: 12px;
    justify-content: center;
    margin-left: 0;
    height: 50%;
}


`
