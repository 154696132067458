import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrap, SidebarLink, SidebarMenu } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrap>
                <SidebarMenu>
                    <SidebarLink to="mobileabout" onClick={toggle}>
                        ABOUT
                    </SidebarLink>
                    <SidebarLink to="zion" onClick={toggle}>
                        $ZION
                    </SidebarLink>
                    <SidebarLink to="merch" onClick={toggle}>
                        MERCH
                    </SidebarLink>
                    <SidebarLink to="discord" onClick={toggle}>
                        P2E GAME
                    </SidebarLink>
                    <SidebarLink to="jobs" onClick={toggle}>
                        COMMUNITY JOBS
                    </SidebarLink>
                    <SidebarLink to="team" onClick={toggle}>
                        TEAM
                    </SidebarLink>
                    <SidebarLink to="faq" onClick={toggle}>
                        FAQ
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrap>
        </SidebarContainer>
    )
}

export default Sidebar;
