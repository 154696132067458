import React from 'react';
import { MerchContainer, MerchImage, MerchWrapper, SubContainer, BigContainer, MerchText, MerchTitle, TextSection, DescTitle, DescText, BuyBtn, BuyLink } from './MerchElements';
import Hat from '../../img/Merch/hat.gif'
import { MerchArea } from "../Navbar/NavbarElements";

const Merch = () => {
  return (
    <MerchContainer {...MerchArea}>
        <MerchWrapper>
            <BigContainer>
            <MerchImage src={Hat}/>
                <TextSection>
            <MerchTitle>**LIMITED** <br/> FIRST EDITION MUSHIES CAP </MerchTitle>
            <BuyLink href="https://form.jotform.com/220189060251144">
            <BuyBtn>BUY NOW</BuyBtn>
            </BuyLink>
            <MerchText>PRICE: $35.00 USDC <br/><br/> ONLY 150 AVAILABLE FOR PURCHASE</MerchText>
            </TextSection>
               
                
            </BigContainer>
            <SubContainer>
            <DescTitle>MUSHIES MERCH</DescTitle>
            <DescText><p>We are so stoked to announce the first edition of our exclusive merch range...<br/> MUSHIE CAPS! <br/> This is by far the highest quality merch in the NFT market right now. 
                <br/>
                <h2>RESERVES</h2>
                [ 150 ] Available to Purchase <br/>
                [ 20 ] Reserved for Staff <br/>
                [ 10 ] Top 10 Ranked (Discord) <br/>
                [ 10 ] Challenges & Competition <br/>
                <br/> Total Available: 190
                </p>
            </DescText>
            </SubContainer>
        </MerchWrapper>
    </MerchContainer>
)
}

export default Merch
