import styled from "styled-components";
import LightBG from '../../img/Backgrounds/LightBG3.png'

export const DiscordContainer = styled.div`
height: 1100px;
width: 100%;
background: #1a6930;
background-image: url(${LightBG});
color: white;
display: flex;
justify-content: center;
align-items: center;
`
export const DiscordWrapper = styled.div`
height: 100%;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
border-bottom: 10px solid white;
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    flex-direction: column;

}
`

export const SubContainer = styled.div`
height: 80%;
width: 45%;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 50%;

}
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 90%;

}
`

export const TitleSection = styled.div`
display: flex;
width: 55%;
height: 15%;
justify-content: center;
align-items: center;
color: white;
font-size: 50px;
font-family: Modak;
background: #184832;
border-radius: 90px;
box-shadow: 5px 5px 10px black;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 95%;
    border-radius: 30px;
    font-size: 40px;
}
@media screen and (max-width: 1520px)
{
    transition: 0.8s all ease;
    font-size: 30px;

}
`

export const TextSection = styled.div`
display: flex;
width: 45%;
height: 70%;
justify-content: center;
padding: 5%;
margin-top: 5%;
color: white;
font-size: 25px;
font-family: Marker;
background: #184832;
border-radius: 25px;
box-shadow: 5px 5px 10px black;
text-align: center;
align-items: center;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 90%;
    font-size: 22px;
    text-align: center;
    padding: 2%;
}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    width: 90%;
    font-size: 18px;
    text-align: center;
    padding: 2%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 13.5px;
    
}
`

export const Leaderboard = styled.iframe`
width: 65%;
height: 95%;
border: 0;
box-shadow: 2px 2px 10px black;
border-radius: 20px;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 95%;
    height: 90%;

}
`
