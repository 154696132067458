import styled from "styled-components";

export const HeroContainer = styled.div`
height: fit-content;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
`
export const AvailText = styled.div`
z-index: 1;
font-family: Modak;
font-size: 35px;
color: white;
bottom: 15%;
position: absolute;

    @media screen and (max-width: 1580px)
    {
        transition: 0.8s all ease;
        font-size: 25px;
    }
    @media screen and (max-width: 1380px)
    {
        transition: 0.8s all ease;
        font-size: 15px;
    }

    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        font-size: 12px;
        bottom: 17%;
    }

`

export const MarketLink = styled.a`
height: 85%;
width: 30%;
border-radius: 20px;
z-index: 4;
margin: 0 10px;
@media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        margin: 0 5px;
        height: 90%;
        width: 100%;
    }
`

export const MarketPics = styled.div`
height: 10%;
width: 12%;
bottom: 7%;
z-index: 2;
position: absolute;
justify-content: center;
align-items: center;
display: flex;
@media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        margin: 0 5px;
        height: 10%;
        width: 20%;
    }
`

export const MarketImage = styled.img`
height: 100%;
width: 100%;
border-radius: 20px;
z-index: 4;
margin-right: 5px;
box-shadow: 2px 2px 5px black;
@media screen and (max-width: 1580px)
{
    transition: 0.8s all ease;
    margin-right: 0px;
    border-radius: 5px;
}
`

export const HeroImage = styled.img`
height: 100%;
width: 100%;
z-index: 0;
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 250%;
    height: 150%;
   
}
`