import React from 'react';
import { BotSection, DescBox, DescText, DescWrapper, TitleSection, TopSection, ZionContainer, ZionPic, ZionWrapper, DescTitle, PartnerSection, Partner, PartnerPic, PartnerName, PartnerLink, PartnerSub } from './ZionElements';
import ZionPict from '../../img/Partners/zion.png'
import Cartel from '../../img/Partners/cats.jpg'
import Dogs from '../../img/Partners/dogs.jpg'
import MoneyBoy from '../../img/Partners/moneyboys.jpg'
import Bored from '../../img/Partners/bored.png'
import Saibo from '../../img/Partners/saibo.jpg'
import Dino from '../../img/Partners/dino.jpg'
import BadCreature from '../../img/Partners/badcreature.jpg'
import { ZionArea } from '../Navbar/NavbarElements';

const Zion = () => {
  return (
    <ZionContainer {...ZionArea}>
        <ZionWrapper>
            <TopSection>
                <TitleSection>
                    $ZION TOKENOMICS
                </TitleSection>
                <DescWrapper>
                    
                    <DescBox>
                        <DescTitle>
                            WHAT IS $ZION?
                        </DescTitle>
                        <DescText>
                            <p>$ZION is the FIRST Multi-Project Utility Token in Solana NFTs. 
                           Initially created by Cat Cartel, $ZION has since become the utility token for 8 projects currently on the Solana Network. Adopters include DEA Dogs, Magic Mushies, Solana Money Boys, Bored Ape Social Club, Saibogu Corp, Dino Kingz, & Bad Creature Society being the latest!</p>
                           
                        </DescText>
                    </DescBox>
                    <ZionPic src={ZionPict}/>
                    <DescBox>
                        <DescTitle>
                            MUSHIES JOIN $ZION
                        </DescTitle>
                        <DescText>
                            <p>
                            Magic Mushies have been allocated 2,000,000 to begin their venture into the $Zion ecosystem. 
                            Mushies have also partnered with CyberFrogs to give holders not only the option to non-escrow stake earning 7 $ZION a day, but also the ability Dual-Stake with a CyberFrog and earn an extra 10%!
                        
                      
                     
                        <br/><a href="https://cyberfrogs.io/staking/">STAKING AVAILABLE HERE</a>
                        </p>
                        </DescText>
                    </DescBox>
                </DescWrapper>
            </TopSection>

            <BotSection>
                <TitleSection>
                    PARTNERS
                </TitleSection>
                <PartnerSection>
                    <PartnerSub>
                    <Partner>
                        <PartnerLink href="https://twitter.com/SolanaCatCartel">
                        <PartnerPic src={Cartel}/>
                        </PartnerLink>
                        <PartnerName>CAT CARTEL</PartnerName>
                    </Partner>
             
                    <Partner>
                        <PartnerLink href="https://twitter.com/SolanaMoneyBoys">
                        <PartnerPic src={MoneyBoy}/>
                        </PartnerLink>
                        <PartnerName>SOLANA MONEY BOYS</PartnerName>
                    </Partner>
                    <Partner>
                        <PartnerLink href="https://twitter.com/BAPESocialClub">
                        <PartnerPic src={Bored}/>
                        </PartnerLink>
                        <PartnerName>BORED APE SOCIAL</PartnerName>
                    </Partner>
                    </PartnerSub>
                    <PartnerSub>
             
                    <Partner>
                        <PartnerLink href="https://twitter.com/SaiboguC">
                        <PartnerPic src={Saibo}/>
                        </PartnerLink>
                        <PartnerName>SAIBOGU CORP</PartnerName>
                    </Partner>
                    <Partner>
                        <PartnerLink href="https://twitter.com/DinoKingzNFT">
                        <PartnerPic src={Dino}/>
                        </PartnerLink>
                        <PartnerName>DINO KINGZ</PartnerName>
                    </Partner>
                    <Partner>
                        <PartnerLink href="https://twitter.com/badcreatureSOC">
                        <PartnerPic src={BadCreature}/>
                        </PartnerLink>
                        <PartnerName>BAD CREATURE</PartnerName>
                    </Partner>
                    </PartnerSub>
                </PartnerSection>
            </BotSection>

        </ZionWrapper>
    </ZionContainer>
  ) 
}

export default Zion;
