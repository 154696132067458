import styled from 'styled-components'
import { Link as LinkRD } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'


export const Bav = styled.nav`
    background: #3634a5;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;

    @media screen and (max-width: 1080px)
    {
        transition: 0.8s all ease;
        height: 55px;
   
        
    }
`

export const Nav = styled.nav`
    background: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: relative;
    top: 0;
    z-index: 10;
    width: 100%;

    @media screen and (max-width: 1080px)
    {
        transition: 0.8s all ease;
        height: 55px;
        position: sticky;
        
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    /* max-width: 1100px; */
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        height: 45px;
    
    }
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 1080px)
    {
        color: #fff;
        display: block;
        position: absolute;
        top: 15px;
        right: 25px;
      
        font-size: 1.8rem;
        cursor: pointer;
    
    }
    @media screen and (max-width: 580px)
    {
        transition: 0.8s all ease;
        top: 15px;
        left: 15px;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 1080px)
    {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px;
    padding: 25px;
`

export const NavLinks = styled(LinkS)`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 2.2rem;
    font-family: Modak;
    color: #6647e2;
    -webkit-text-stroke: 1px #3634a5;
    &:hover{
        color: #fff;
    }
    @media screen and (max-width: 1380px)
    {
        transition: 0.8s all ease;
        font-size: 1.8rem;
   
        
    }
    /* &.active{
        border-bottom: 3px solid #fff;
    } */
`

export const AboutArea = {
    id: 'about',
}
export const MobileAboutArea = {
    id: 'mobileabout',
}
export const ZionArea = {
    id: 'zion',
}
export const MerchArea = {
    id: 'merch',
}
export const DiscordArea = {
    id: 'discord',
}
export const JobArea = {
    id: 'jobs',
}
export const TeamArea = {
    id: 'team',
}
export const FaqArea = {
    id: 'faq',
}