export const Data = [
    {
        question: 'What is Magic Mushies?',
        answer: 'Magic Mushies is a Solana NFT collection of 4,444 mushroom people making their mark on the very fabric of the Solana ecosystem.  Owning one offers exclusive benefits to holders like staking and access to a P2E game and one of the most active and friendly communities on Solana with a Community Job Initiative where expert staff entertain and share advice on trading, crypto, and much more.' 
    },
    {
        question: 'When did Magic Mushies mint?',
        answer: '4,444 Magic Mushies were minted January 5th at a price of 0.44 Sol per mint, and have since performed well on secondary markets like Magic Eden trading far above that original mint price.' 
    },
    {
        question: 'How do holders get $ZION?',
        answer: 'Our Staking and Tokenomics programs allow holders to currently receive 7 $ZION coin per day when staked at cyberfrogs.io/staking (linked in $ZION section). $ZION is a currency that can be used in our Discord economy, buy merch with it, trading with other projects that use $ZION, requesting certain services we offer in discord, and much more.' 
    },
    {
        question: 'What are Community Jobs?',
        answer: 'Our experts hired from the Community Job Initiative give advice on the markets for NFTs and crypto, potential upcoming projects to look at, and classic group entertainment in the form of games and events.' 
    },
    {
        question: 'What is the future for this project?',
        answer: 'Magic Mushies have already partnered with Cartel Cats to bring Tokenomics to the masses with the Zion coin, and staking is being implemented for holders to be rewarded for refraining from the devilish “listings”. Future plans for the project include implementing this utility and potential partnerships with big companies, as well as an ongoing focus on growing the community and offering more and more to holders through jobs, collabs and WL spots, DAO formation, and much more.' 
    },
    {
        question: 'What is Magic Mushies lore?',
        answer: 'Humanity was nearly overtaken by AI, but 4,444 Humans macro-dosed on magic mushrooms to unlock the brain power to defeat the AI. The only cost was their form; they’re now giant mushrooms. The remaining mushies disagree on how to live, with the Light side led by Yodolf the Vibrant believe in peace and love while the Dark side led by Sansor the Dark Overlord believe in anarchy and chaos. Join the Mushies and choose your side for unique events and games between the factions.' 
    },
];