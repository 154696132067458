import styled from "styled-components";

export const MerchContainer = styled.div`
display: flex;
position: relative;
width: 100%;
height: 900px;
background: #184832;
justify-content: center;
align-items: center;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    height: 900px;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    height: 1450px;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    height: 1150px;
}
`

export const MerchWrapper = styled.div`
height: 100%;
width: 90%;
display: flex;
justify-content: center;
align-items: center;
border-bottom: 5px solid white;
z-index: 2;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    flex-direction: column;
}
`

export const BigContainer = styled.div`
width: 40%;
height: 90%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
/* background: #1a6930;
border-radius: 20px;
box-shadow: 5px 5px 10px black; */
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 50%;
    
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 100%;
    height: 50%;
}
`

export const SubContainer = styled.div`
width: 40%;
height: 90%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    width: 50%;
    
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    width: 100%;
    height: 45%;
}
`

export const DescTitle = styled.div`
display: flex;
width: 55%;
height: 15%;
justify-content: center;
align-items: center;
color: white;
font-size: 50px;
font-family: Modak;
background: #1a6930;
border-radius: 90px;
box-shadow: 5px 5px 10px black;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    font-size: 45px;
    width: 80%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 30px;

}
`

export const DescText = styled.div`
display: flex;
width: 55%;
height: 70%;
justify-content: center;
align-items: center;
padding: 4%;
margin-top: 5%;
color: white;
font-size: 25px;
font-family: Marker;
background: #1a6930;
border-radius: 25px;
box-shadow: 5px 5px 10px black;
text-align: center;
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    font-size: 22px;
    width: 80%;
}
@media screen and (max-width: 1580px)
{
    transition: 0.8s all ease;
    font-size: 18px;
    width: 80%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 14px;

}
`

export const MerchImage = styled.img`
display: flex;
height: 55%;
width: 50%;
border-radius: 30px;
box-shadow: 2px 2px 10px black;
margin: 3%;

@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    margin: 0;
    height: 40%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 65%;
    height: 45%;
}
`

export const MerchTitle = styled.div`
height: 30%;
width: 100%;
color: white;
font-size: 35px;
font-family: Modak;
justify-content: center;
align-items: center;
text-align: center;
-webkit-text-stroke: 1px #1a6930;

@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 18px;

}
`

export const BuyBtn = styled.button`
height: 100%;
width: 100%;
background: #1a6930;
border-radius: 25px;

font-family: Modak;
color: white;
font-size: 35px;
&:hover{
font-size: 36px;
box-shadow: 2px 2px 4px black;
cursor: pointer;
}
@media screen and (max-width: 1920px)
{
    transition: 0.8s all ease;
    font-size: 25px;
    &:hover{
    font-size: 30px;
        }
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 20px;
    &:hover{
    font-size: 25px;
        }
}
`
export const BuyLink = styled.a`
height: 30%;
width: 25%;
margin-top: 2%;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;

@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    width: 40%;

}
`

export const MerchText = styled.div`
height: 30%;
width: 100%;
color: white;
font-size: 25px;
font-family: Dosis;
margin-top: 4%;
justify-content: center;
align-items: center;
text-align: center;

p {
text-align: left;
}
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    margin-top: 2%;
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    margin-top: 5%;
    font-size: 15px;

}
`

export const TextSection = styled.div`
display:flex;
flex-direction: column;
width: 100%;
margin-top: 2%;
justify-content: center;
align-items: center;
@media screen and (max-width: 1300px)
{
    transition: 0.8s all ease;
    margin-top: 5%;

}
`
