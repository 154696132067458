import React from 'react'
import { ImgContainer, TeamContainer, TeamMember, TeamTitle, TeamImg, TeamInfo, SocialIcon, TeamWrapper } from "../Team/TeamElements.js"
import SambinoIcon from "../../img/Team/SambinoIcon.png"
import TeaIcon from "../../img/Team/TeaIcon.png"
import GunterIcon from "../../img/Team/GunterIcon.png"
import JangoIcon from "../../img/Team/JangoIcon.jpg"
import ChadIcon from "../../img/Team/ChadIcon.png"
import SkellIcon from "../../img/Jobs/skell.jpg"
import WookieIcon from '../../img/Jobs/wookie.jpg'

import { FaTwitter } from 'react-icons/fa'
import { TeamArea } from '../Navbar/NavbarElements.js'

const Team = () => {
    return (
   
   <TeamContainer {...TeamArea}>
      <TeamTitle>
                TEAM
              </TeamTitle>
              <TeamWrapper>
              <ImgContainer>
  
                <TeamMember>
                <SocialIcon href="https://twitter.com/MushieGunter">
                      
                  <TeamImg src = {GunterIcon}/>

                  </SocialIcon>
                  <TeamInfo>
                    <h1>Gunter</h1>
                    <p>Founder</p>
            
                  </TeamInfo>
                </TeamMember>



                <TeamMember>
                <SocialIcon href="https://twitter.com/Wooki3Vision">
                   
                  <TeamImg src = {WookieIcon}/>
       
                  </SocialIcon>
                  <TeamInfo>
                    <h1>Wookie</h1>
                    <p>Community Lead</p>
                  
                  </TeamInfo>
        
                </TeamMember>

                <TeamMember>
                <SocialIcon href="https://twitter.com/CryptoSambino">
                
                  <TeamImg src = {SambinoIcon}/>
              
                  </SocialIcon>
                  <TeamInfo>
                    <h1>Sambino</h1>
                   <p>Developer</p>
                  </TeamInfo>
             
                </TeamMember>
              </ImgContainer>

             <ImgContainer>
             
             <TeamMember>
                <SocialIcon href="https://twitter.com/janbrek">
                     
                  <TeamImg src = {JangoIcon}/>
              
                  </SocialIcon>
                  <TeamInfo>
                    <h1>Jango</h1>
                    <p>Artist</p>
                  </TeamInfo>
             
                </TeamMember>

             <TeamMember>
                <SocialIcon href="https://twitter.com/lander_crypto">
                <TeamImg src = {ChadIcon}/>
                </SocialIcon>
                <TeamInfo>
                  <h1>Magic Chad</h1>
                  <p>Head Mod</p>
                </TeamInfo>
            
              </TeamMember>

             <TeamMember>
                <SocialIcon href="https://twitter.com/Skellbro_">
                     
                  <TeamImg src = {SkellIcon}/>
              
                  </SocialIcon>
                  <TeamInfo>
                    <h1>Skell</h1>
                    <p>Artist</p>
                  </TeamInfo>
             
                </TeamMember>

            </ImgContainer>
            </TeamWrapper>
   </TeamContainer>
    )
}

export default Team
