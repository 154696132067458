import styled from "styled-components";
import LightBG2 from '../../img/Backgrounds/LightBG2.png'

export const ZionContainer = styled.div`
display: flex;
height: 1600px;
width: 100%;
justify-content: center;
align-items: center;
position: relative;
background: #1a6930;
background-image: url(${LightBG2});
color: white;
@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    height: 1000px;
}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    height: 1200px;
}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    height: 800px;
}
`

export const ZionWrapper = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 90%;
justify-content: center;
align-items: center;
position: absolute;
border-bottom: 5px solid white;
border-top: 5px solid white;
z-index: 2;
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    width: 90%;
}
`

export const TopSection = styled.div`
display: flex;
height: 40%;
width: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 1%;
@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    height: 50%;
}

`

export const TitleSection = styled.div`
width: 100%;
height: 25%;
font-size: 75px;
font-family: Modak;
text-align: center;
align-items: center;
justify-content: center;
display: flex;
margin: 1%;
-webkit-text-stroke: 1px #184832;
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    font-size: 35px;
    margin-top: 3%;
    
}
`

export const DescWrapper = styled.div`
display: flex;
height: 90%;
width: 100%;
justify-content: center;
align-items: center;
flex-direction: row;
background: #184832;
border-radius: 30px;

box-shadow: 1px 1px 20px black;
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    flex-direction: column;
}
`

export const DescBox = styled.div`
display: flex;
height: 90%;
width: 50%;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 0 2%;
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    width: 90%;
}

`

export const DescTitle = styled.div`
width: 100%;
height: 20%;
font-size: 55px;
font-family: Modak;
text-align: center;
align-items: center;
justify-content: center;
display: flex;
-webkit-text-stroke: 1px #1a6930;

@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    font-size: 35px;
}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    font-size: 25px;
}
`

export const DescText = styled.div`
width: 100%;
height: 60%;
font-size: 30px;
font-family: Dosis;
text-align: center;
align-items: center;
justify-content: center;
display: flex;

a{
    font-family: Modak;
    color: white;

    &:hover{
        font-size: 32px;
    }
}
   

@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    font-size: 20px;
    a{
        &:hover{
            font: 22px;
        }
    }
}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    font-size: 18px;
    a{
        &:hover{
            font: 20px;
        }
    }
}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    font-size: 14px;
    a{
        &:hover{
            font: 15px;
        }
    }
}
@media screen and (max-width: 580px)
{
    transition: 0.8s all ease;
    font-size: 12px;
    a{
        &:hover{
            font: 13px;
        }
    }
}



`
export const ZionPic = styled.img`
display: flex;
height: 70%;
width: 20%;
border-radius: 90%;
margin: 0 2%;
box-shadow: 1px 1px 20px black;

@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
   height: 50%;
}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
   height: 40%;
}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    height: 20%;
}
`

export const BotSection = styled.div`
display: flex;
height: 60%;
width: 100%;
margin-bottom: 2%;

justify-content: center;
align-items: center;
flex-direction:column;
@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    margin-bottom: 0;
    height: 50%;
}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    height: 50%;
  
}
`

export const PartnerSection = styled.div`
height: 90%;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex;
justify-content: flex;


@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    height: 80%;
}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    font-size: 25px;
    height: 75%;
}
`

export const PartnerSub = styled.div`
height: 45%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;

`

export const Partner = styled.div`

height: 90%;
width: 22%;
display: flex;
flex-direction: column;
margin: 0 3%;
justify-content: center;
align-items: center;
background: #184832;
border-radius: 20px;
box-shadow: 1px 1px 20px black;
@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    height: 90%;
    width: 25%;
    margin: 0 3%;

}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    border-radius: 8px;
    height: 90%;
    box-shadow: 0px 0px 0px transparent;
    background: transparent;
    margin: 0 5%;
    width: 30%;
    
}
`

export const PartnerPic = styled.img`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 2px 2px 10px black;
border: 3px solid #1a6930;
border-radius: 20px;
&:hover
{
    height: 99%;
    width: 99%;
}
`

export const PartnerName = styled.div`
width: 95%;
height: 30%;
font-size: 33px;
font-family: Modak;
text-align: center;
align-items: center;
justify-content: center;
display: flex;

-webkit-text-stroke: 1px #1a6930;
@media screen and (max-width: 1680px)
{
    transition: 0.8s all ease;
    font-size: 20px;
}
@media screen and (max-width: 1380px)
{
    transition: 0.8s all ease;
    font-size: 12px;
}
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    font-size: 10px;
}

`

export const PartnerLink = styled.a`
height: 60%;
width: 50%;
padding: 1%;
@media screen and (max-width: 750px)
{
    transition: 0.8s all ease;
    width: 90%;
    padding: 10px;
    
}
`

