import './App.css';
import { DiscordContainer, DiscordWrapper, Leaderboard, SubContainer, TextSection, TitleSection } from './Components/DiscordGame/DiscordElements';
import Team from './Components/Team/Team';
import { AvailText, HeroContainer, HeroImage, MarketImage, MarketLink, MarketPics } from './Components/Hero/HeroElements';
import Hero from '../src/img/Hero/fullscale.png';
import AboutSection from './Components/About/About';
import MagicEden from '../src/img/Hero/magic.png';
import AlphaArt from '../src/img/Hero/alpha.png';
import AccordionC from './Components/FAQ/AccordianElements';
import Footer from './Components/Footer/Footer';
import Merch from './Components/Merch/Merch';
import Zion from './Components/ZION/Zion';
import Community from './Components/Community/Community';
import Sidebar from './Components/Sidebar';
import {Nav, NavbarContainer, MobileIcon, NavMenu, NavItem, NavLinks, Bav, AboutArea} from './Components/Navbar/NavbarElements'
import { FaBars } from 'react-icons/fa'
import { useEffect, useState } from "react";
import { DiscordArea } from './Components/Navbar/NavbarElements';
import { MobileAboutArea } from './Components/Navbar/NavbarElements';


function App() {
  
const[isOpen, setIsOpen] = useState(false)
const[clicked, setClicked] = useState(false)

 const toggle = () => {
     setIsOpen(!isOpen)
}

  return (
    <main>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Bav {...MobileAboutArea}/>
      <Nav> 
            <NavbarContainer>

                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>

                <NavMenu>
                <NavItem>
                    <NavLinks to ='about' smooth={true} duration={500} spy={true} exact='true'>ABOUT</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='zion' smooth={true} duration={500} spy={true} exact='true'>$ZION</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='merch' smooth={true} duration={500} spy={true} exact='true'>MERCH</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='discord' smooth={true} duration={500} spy={true} exact='true'>P2E GAME</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='jobs' smooth={true} duration={500} spy={true} exact='true'>JOBS</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='team' smooth={true} duration={500} spy={true} exact='true'>TEAM</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='faq' smooth={true} duration={500} spy={true} exact='true'>FAQ</NavLinks>
                    </NavItem>
                </NavMenu>

            </NavbarContainer>
        </Nav>
      <HeroContainer >
        <HeroImage src={Hero}/>
        <AvailText>
          AVAILABLE AT
        </AvailText>
        <MarketPics>
          <MarketLink href="https://magiceden.io/marketplace/magic_mushies">
          <MarketImage src={MagicEden}/>  
          </MarketLink>
          
          <MarketLink href="https://alpha.art/collection/magic-mushies">
          <MarketImage src={AlphaArt}/>  
          </MarketLink>
        </MarketPics>
      </HeroContainer>


      <AboutSection/>

      <Zion/>

      <Merch/>

      <DiscordContainer {...DiscordArea}>
        <DiscordWrapper>
      <SubContainer>
        <TitleSection>
          P2E DISCORD GAME
        </TitleSection>
        <TextSection>
          
          Discord game where you can earn real world value and Real NFTs!
          <br/>
          This game is ONLY available to verified holders in our Discord.
          <br/> <br/>
          So far, Mushie holders have purchased: <br/> <br/>
          45x Magic Mushies <br/>
          20x Krooks <br/>
          5x Cat Cartel <br/>
          5x DEA Dogs <br/>
          3x Solana Money Boys <br/>
          3x Zero Pigs Given <br/>
          1x BOTBORG <br/>
          <br/> 
          ALL WITH IN-GAME MONEY <br/> <br/>
          
          
        </TextSection>
      </SubContainer>
        <SubContainer>
      <Leaderboard src='https://unbelievaboat.com/leaderboard/900216115177881640/widget'/>
      </SubContainer>
      </DiscordWrapper>
      </DiscordContainer>

     <Community/>

      <Team/>

      <AccordionC/>

      <Footer/>
      

    </main>
  );
}

export default App;
