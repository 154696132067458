import React from 'react';
import { CommText, CommTitle, CommunityContainer, CommWrapper, Member, MemberSection, SubContainer, DubContainer, MemberLink, MemberImg, MemberName, MemberJob, TextSection } from './CommunityElements';
import { JobArea } from '../Navbar/NavbarElements';
import Swaggie from '../../img/Jobs/swaggie.jpg'
import Pencil from '../../img/Jobs/pencil.jpg'
import Wookie from '../../img/Jobs/wookie.jpg'
import Vision from '../../img/Jobs/vision.jpg'
import Ron from '../../img/Jobs/ronjohn.jpg'
import Patrick from '../../img/Jobs/patrick.jpg'
import Pableroy from '../../img/Jobs/pableroy.jpg'
import Ceeb from '../../img/Jobs/ceeb.jpg'
import Kristian from '../../img/Jobs/kristian.jpg'
import IH from '../../img/Jobs/ihpic.JPG'
import Krispet from '../../img/Jobs/krispet.JPG'
import Verts from '../../img/Jobs/verts.png'

const Community = () => {
  return (
   <CommunityContainer {...JobArea}>
       <CommWrapper>
       <DubContainer>
         <CommTitle>COMMUNITY JOBS</CommTitle>
         <CommText>
         <p> Our community job incentive was created as a way to reward holders for their talents used to benefit the Magic Mushies' community! <br/><br/>
            
        <p2>Examples of Services:</p2>
        
        <p><p3>Wookie's Market Scan </p3><br/>
           Wookie hosts a weekly live stream for holders to stay up-to-date on the technical analysis of many cryptocurriences including BTC, ETH, SOL, and more!</p>


            <p><p3>Mushies Alpha Calls</p3><br/>
            We have channels designated for holders where our Alpha Team break down their researched favorite picks for projects minting soon and/or that are undervalued!
            </p>

            <p><p3>Mushies Games & Events</p3><br/>
            Pableroy and PatrickMeowler are hosting events for holders to win in-game economy prizes like Mushies of Catan or Light v Dark Mushies Chess Club!
            </p>
       
        </p>
            
       

         </CommText>
         </DubContainer>
       <SubContainer>
          <MemberSection>
            <Member>
              <MemberLink href="https://twitter.com/bbsolicon">
                <MemberImg src={Swaggie}/>
              </MemberLink>
              <TextSection>
              <MemberName>Swaggie</MemberName>
              <MemberJob>DISCORD MOD</MemberJob>
              </TextSection>
            </Member>

            <Member>
              <MemberLink href="https://twitter.com/trav_sol">
                <MemberImg src={Pencil}/>
              </MemberLink>
              <TextSection>
              <MemberName>Pencil</MemberName>
              <MemberJob>DISCORD MOD</MemberJob>
              </TextSection>
            </Member>
           
            <Member>
              <MemberLink href="https://twitter.com/Kristia86019936">
                <MemberImg src={Kristian}/>
              </MemberLink>
              <TextSection>
              <MemberName>Kristian</MemberName>
              <MemberJob>DISCORD MOD</MemberJob>
              </TextSection>
            </Member>
          </MemberSection>

          <MemberSection>

<Member>
  <MemberLink href="https://twitter.com/patrickmeowler">
    <MemberImg src={Patrick}/>
  </MemberLink>
  <TextSection>
  <MemberName>Patrick</MemberName>
  <MemberJob>EVENT HOST</MemberJob>
  </TextSection>
</Member>

<Member>
  <MemberLink href="https://twitter.com/Wooki3Vision">
    <MemberImg src={Wookie}/>
  </MemberLink>
  <TextSection>
  <MemberName>Wookie</MemberName>
  <MemberJob>MOD/HOST</MemberJob>
  </TextSection>
</Member>

<Member>
  <MemberLink href="https://twitter.com/MadeOuttaMud">
    <MemberImg src={Pableroy}/>
  </MemberLink>
  <TextSection>
  <MemberName>Pableroy</MemberName>
  <MemberJob>EVENT HOST</MemberJob>
  </TextSection>
</Member>



</MemberSection>

          <MemberSection>
          <Member>
              <MemberLink href="https://twitter.com/vertseyy">
                <MemberImg src={Verts}/>
              </MemberLink>
              <TextSection>
              <MemberName>Verts</MemberName>
              <MemberJob>ALPHA CALLER</MemberJob>
              </TextSection>
            </Member>

          <Member>
              <MemberLink href="https://twitter.com/ceeeebastian">
                <MemberImg src={Ceeb}/>
              </MemberLink>
              <TextSection>
              <MemberName>Ceeeeeb</MemberName>
              <MemberJob>ALPHA CALLER</MemberJob>
              </TextSection>
            </Member>

            <Member>
              <MemberLink href="https://twitter.com/RonJohnJim1">
                <MemberImg src={Ron}/>
              </MemberLink>
              <TextSection>
              <MemberName>RonJohn</MemberName>
              <MemberJob>SNIPING/BOTS</MemberJob>
              </TextSection>
            </Member>

            </MemberSection>

            <MemberSection>

  
            <Member>
              <MemberLink href="https://twitter.com/magic_mushies">
                <MemberImg src={IH}/>
              </MemberLink>
              <TextSection>
              <MemberName>IH</MemberName>
              <MemberJob>SOCIAL MEDIA</MemberJob>
              </TextSection>
            </Member>
            <Member>
              <MemberLink href="https://twitter.com/magic_mushies">
                <MemberImg src={Krispet}/>
              </MemberLink>
              <TextSection>
              <MemberName>Krispet</MemberName>
              <MemberJob>COLLAB LEAD</MemberJob>
              </TextSection>
            </Member>
            <Member>
              <MemberLink href="https://twitter.com/vision_nft">
                <MemberImg src={Vision}/>
              </MemberLink>
              <TextSection>
              <MemberName>CRvision</MemberName>
              <MemberJob>SOCIAL MEDIA</MemberJob>
              </TextSection>
            </Member>

            </MemberSection>
         </SubContainer>

       </CommWrapper>
   </CommunityContainer>
  ) 
};

export default Community;
